import React, { useState, useRef } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Row,
  Form,
  Table,
  Label,
} from 'reactstrap'
import Select from 'react-select'
import { NotificationManager } from 'react-notifications'
import draftToHtml from 'draftjs-to-html'
import {
  convertFromHTML,
  ContentState,
  EditorState,
  convertToRaw,
} from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import useQuery from 'hooks/useQuery'
import { useForm, Controller } from 'react-hook-form'
import { confirmAlert } from 'react-confirm-alert'
import { useHistory } from 'react-router'
import CropperModal from 'components/cropperModal'
import { dataUrlToFile } from 'utli/fileConverter'
import { sendData } from 'utli/apiService'
import { getReadableDateDisplay } from 'utli/convertToHumanReadableTime'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import './edit.scss'
const EditProduct = (props) => {
  const [initState, setInitState] = useState({
    openCropper: false,
    image: null,
    croppedImage: null,
    submitLoading: false,
    imageError: false,
    isEdit: false,
    editImageID: null,
    description: '',
    otherSizeName: '',
  })

  const inputFile = useRef(null)
  const router = useHistory()
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const converttoHtml = (value) => {
    if (typeof value === 'object') {
      const rawContentState = convertToRaw(value.getCurrentContent())
      const markup = draftToHtml(rawContentState)
      return markup
    } else {
      return value
    }
  }

  const onSizeAdd = async () => {
    if (initState.otherSizeName) {
      const response = await sendData(
        `products/updateSize/${productinfo?.id}`,
        { newSize: initState.otherSizeName }
      )
      if (response) {
        NotificationManager.success('Save')
        //    window.location.reload()
      }
    }
  }

  const onColourAdd = async () => {
    if (initState.otherColourName && initState.otherColourLink) {
      const values = {
        name: initState.otherColourName,
        link: initState.otherColourLink,
      }
      let otherColour = initState.colours
      otherColour.push(values)
      const response = await sendData(
        `products/updateColour/${productinfo?.id}`,
        { colour: JSON.stringify(otherColour) }
      )
      if (response) {
        NotificationManager.success('Save')
        setInitState({
          ...initState,
          colours: otherColour,
        })
      }
    }
  }

  const onColourDelete = async (element) => {
    const colours = initState.colours.filter((size) => {
      return size.name !== element.name
    })
    const response = await sendData(
      `products/updateColour/${productinfo?.id}`,
      {
        otherColour: JSON.stringify(colours),
      }
    )
    if (response) {
      NotificationManager.success('Save')
      setInitState({
        ...initState,
        colours: colours,
      })
    }
  }

  const { response, loading, error } = useQuery('categories/getList')
  const categories = response?.data?.map((element) => ({
    value: element.id,
    label: element.name + ` (${element.main_type})`,
  }))

  const {
    response: { productinfo },
    loading: productLoading,
    error: productLoadingErr,
  } = useQuery(`products/detail/${props.match.params.id}`)

  React.useEffect(() => {
    setInitState({
      ...initState,
      sizes: productinfo?.other_size ? JSON.parse(productinfo?.other_size) : [],
      colours: productinfo?.other_colour
        ? JSON.parse(productinfo?.other_colour)
        : [],
      description: converttoHtml(productinfo?.description),
    })
  }, [productinfo])

  const getDraft = (sampleMarkup) => {
    const blocksFromHTML = convertFromHTML(sampleMarkup ? sampleMarkup : '')
    const state = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    )
    return EditorState.createWithContent(state)
  }

  const submitData = async (values) => {
    setInitState({ ...initState, submitLoading: true })
    const formData = new FormData()
    formData.append('name', values.name)
    formData.append('price', values.price)
    formData.append('code', values.code)
    formData.append('colour', values.colour)
    formData.append('size', values.size)
    formData.append('description', initState.description)
    formData.append('category_id', values.category_id)
    formData.append('id', props.match.params.id)
    const response = await sendData('products/update', formData)
    if (response) {
      NotificationManager.success('Save')
      setInitState({
        ...initState,
        submitLoading: false,
      })
      window.location.reload()
    }
  }

  const uploadImage = async (image) => {
    setInitState({ ...initState, submitLoading: true })
    const formData = new FormData()
    const imageFile = await dataUrlToFile(image)
    formData.append('image', imageFile)
    let urlType = initState.isEdit
      ? `updateImage/${initState.editImageID}`
      : `saveimage/${props.match.params.id}`
    const response = await sendData(`products/${urlType}`, formData)
    if (response) {
      NotificationManager.success('Uploaded')
      setInitState({
        ...initState,
        submitLoading: false,
      })
      window.location.reload()
    }
  }

  const deleteImage = async ({ id }) => {
    setInitState({ ...initState, submitLoading: true })
    const response = await sendData(`products/deleteimage/${id}`)
    if (response) {
      NotificationManager.success('Deleted')
      setInitState({
        ...initState,
        submitLoading: false,
      })
      window.location.reload()
    }
  }

  const onDeleteSize = async (element) => {
    const response = await sendData(`products/deleteSize/${productinfo.id}`, {
      sizeID: element.id,
    })
    if (response) {
      NotificationManager.success('Deleted')
      setInitState({
        ...initState,
        submitLoading: false,
      })
      window.location.reload()
    }
  }

  const onColourView = async (element) => {
    window.location = `/product/edit/${element.id}`
  }

  const updateImage = async (element) => {
    setInitState({ ...initState, isEdit: true, editImageID: element.id })
    inputFile.current.click()
  }

  const deleteProduct = async () => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete? Data cannot be recovered.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const response = await sendData(
              `products/delete/${props.match.params.id}`
            )
            if (response) {
              router.replace('/product')
            }
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    })
  }

  if (loading || productLoading) return <h3>Loading...</h3>
  if (error || productLoadingErr) return <h3>{error.response.message}</h3>
  return (
    <Container>
      <Form onSubmit={handleSubmit(submitData)}>
        <Card>
          <CardTitle className="pt-2 pl-4 bg-info">
            <h3 className="text-white">Edit Product</h3>
          </CardTitle>
          <CardBody>
            <Row>
              <Col md="6" sm="12">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  {...register('name', {
                    required: 'Name is required',
                  })}
                  defaultValue={productinfo?.name}
                />
                {errors.name && (
                  <span className="text-danger">{errors?.name?.message}</span>
                )}
              </Col>
              <Col md="6" sm="12">
                <label>Code</label>
                <input
                  type="text"
                  className="form-control"
                  {...register('code', {
                    required: 'Code is required',
                  })}
                  defaultValue={productinfo?.code}
                />
                {errors.code && (
                  <span className="text-danger">{errors?.code?.message}</span>
                )}
              </Col>
              <Col sm="12" style={{ paddingBottom: '20px' }}>
                <label className="mt-4">Category</label>
                <Controller
                  control={control}
                  name="category_id"
                  rules={{ required: true }}
                  defaultValue={categories[0]?.value}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      inputRef={ref}
                      classNamePrefix="addl-class"
                      options={categories}
                      defaultValue={categories[0]?.value}
                      value={categories.find((c) => c.value === value)}
                      onChange={(val) => onChange(val.value)}
                    />
                  )}
                />
                {errors.category_id && (
                  <span className="text-danger">
                    {errors?.category_id?.message}
                  </span>
                )}
              </Col>
              <Col md="6" sm="12">
                <label>Colour</label>
                <input
                  type="color"
                  className="form-control"
                  {...register('colour', {
                    required: 'colour is required',
                  })}
                  defaultValue={productinfo?.colour}
                />
                {errors.colour && (
                  <span className="text-danger">{errors?.colour?.message}</span>
                )}
              </Col>
              <Col md="6" sm="12">
                <label>size</label>
                <input
                  type="text"
                  className="form-control"
                  {...register('size', {
                    required: 'Size is required',
                  })}
                  defaultValue={productinfo?.size}
                />
                {errors.size && (
                  <span className="text-danger">{errors?.size?.message}</span>
                )}
              </Col>

              <Col md="6" sm="12" className="mt-3">
                <label>Price</label>
                <input
                  type="number"
                  className="form-control"
                  {...register('price', {
                    required: 'Price is required',
                  })}
                  defaultValue={productinfo.price}
                />
                {errors.price && (
                  <span className="text-danger">{errors?.price?.message}</span>
                )}
              </Col>

              <Col sm="12" className="mt-3 editor-main">
                <label>Description</label>
                <Editor
                  toolbarClassName="editor-toolbar"
                  wrapperClassName="editor-wrapper"
                  editorClassName="editor"
                  defaultEditorState={getDraft(productinfo.description)}
                  toolbar={{ options: ['list'] }}
                  name="coordination"
                  onEditorStateChange={(editorState) => {
                    const rawContentState = convertToRaw(
                      editorState.getCurrentContent()
                    )
                    const markup = draftToHtml(rawContentState)
                    setInitState({ ...initState, description: markup })
                  }}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="d-flex justify-content-center">
            <Button
              color="default"
              type="submit"
              disabled={initState.submitLoading}
            >
              Save
            </Button>
            <Button
              color="danger"
              disabled={initState.submitLoading}
              onClick={deleteProduct}
            >
              Delete
            </Button>
          </CardFooter>
        </Card>
      </Form>
      <Card>
        <CardTitle className="pt-2 pl-4 bg-info">
          <h3 className="text-white">Edit Images</h3>
        </CardTitle>
        <div
          className="d-flex align-items-center justify-content-center"
          onClick={() => {
            setInitState({ ...initState, isEdit: false })
            inputFile.current.click()
          }}
          style={{
            width: 220,
            height: 350,
            background: 'grey',
            cursor: 'pointer',
            marginLeft: '45%',
            fontSize: 70,
            color: 'white',
          }}
        >
          +
        </div>
        <CardBody className="d-flex d-flex-wrap" style={{ overflow: 'auto' }}>
          {productinfo?.productimages?.map((element, index) => (
            <div className="img-container" key={`produut-image-${index}`}>
              <img
                width="220"
                height="350"
                src={element.image}
                alt="edited result"
                onClick={() => updateImage(element)}
              />
              {index > 0 && (
                <span
                  className="btn-inner--icon"
                  onClick={() => deleteImage(element)}
                >
                  <i className="fas fa-trash text-danger" />
                </span>
              )}
            </div>
          ))}

          <input
            type="file"
            id="file"
            ref={inputFile}
            style={{ display: 'none' }}
            onChange={(event) => {
              setInitState({
                ...initState,
                openCropper: true,
                image: URL.createObjectURL(event.target.files[0]),
              })
            }}
          />
        </CardBody>
      </Card>
      {initState.openCropper && (
        <CropperModal
          isShow={initState.openCropper}
          image={initState.image}
          close={() => setInitState({ ...initState, openCropper: false })}
          setCroppedImage={(image) => uploadImage(image)}
          x={4}
          y={6}
        />
      )}
      <Card>
        <CardTitle className="pt-2 pl-4 bg-info">
          <h3 className="text-white">Price Track</h3>
        </CardTitle>
        <CardBody>
          <Table>
            <thead>
              <tr>
                <th>No</th>
                <th>Price</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {productinfo?.price_tracks?.map((price, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{price.price}</td>
                  <td>{getReadableDateDisplay(price.created_at)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <Card>
        <CardTitle className="pt-2 pl-4 bg-info">
          <h3 className="text-white">Size</h3>
        </CardTitle>
        <CardTitle className="pt-2 pl-4">
          <Row>
            <Col md="4">
              <Label>Name</Label>
              <input
                type="text"
                className="form-control"
                defaultValue={initState.otherSizeName}
                onChange={(e) => {
                  setInitState({
                    ...initState,
                    otherSizeName: e.target.value,
                  })
                }}
              />
            </Col>

            <Col md="4">
              <br />
              <Button color="primary" type="submit" onClick={onSizeAdd}>
                Add
              </Button>
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          <Table>
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {initState.sizes.map((element, index) => (
                <tr key={`size-index-${index}`}>
                  <td>{index + 1}</td>
                  <td>{element.size}</td>
                  <td>
                    {element.size !== productinfo.size && (
                      <Button
                        color="danger"
                        type="button"
                        size="small"
                        onClick={() => onDeleteSize(element)}
                      >
                        Delete
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>

      <Card>
        <CardTitle className="pt-2 pl-4 bg-info">
          <h3 className="text-white">Colour</h3>
        </CardTitle>
        <CardTitle className="pt-2 pl-4">
          <Row>
            <Col md="4">
              <Label>Name</Label>
              <input
                type="color"
                defaultValue={initState.otherColourName}
                className="form-control"
                onChange={(e) => {
                  setInitState({
                    ...initState,
                    otherColourName: e.target.value,
                  })
                }}
              />
            </Col>
            <Col md="4">
              <Label>Link</Label>
              <input
                defaultValue={initState.otherColourLink}
                type="text"
                className="form-control"
                onChange={(e) => {
                  setInitState({
                    ...initState,
                    otherColourLink: e.target.value,
                  })
                }}
              />
            </Col>
            <Col md="4">
              <br />
              <Button color="primary" type="submit" onClick={onColourAdd}>
                Add
              </Button>
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          <Table>
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Link</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {initState.colours.map((element, index) => (
                <tr key={`size-index-${index}`}>
                  <td>{index + 1}</td>
                  <td>{element.name}</td>
                  <td>{element.link}</td>
                  <td>
                    <Button
                      type="danger"
                      size="small"
                      onClick={() => onColourDelete(element)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>

      {/* <Card>
        <CardTitle className="pt-2 pl-4 bg-info">
          <h3 className="text-white">Colour</h3>
        </CardTitle>
        <CardTitle className="pt-2 pl-4">
          <Row>
            <Col md="4">
              <Label>Name</Label>
              <input
                type="text"
                defaultValue={initState.otherColourName}
                className="form-control"
                onChange={(e) => {
                  setInitState({
                    ...initState,
                    otherColourName: e.target.value,
                  })
                }}
              />
            </Col>
            <Col md="4">
              <Label>Colour</Label>
              <input
                type="color"
                defaultValue={initState.otherColourName}
                className="form-control"
                onChange={(e) => {
                  setInitState({
                    ...initState,
                    otherColourName: e.target.value,
                  })
                }}
              />
            </Col>
            <Col md="4">
              <br />
              <Button color="primary" type="submit" onClick={onColourAdd}>
                Add
              </Button>
            </Col>
          </Row>
        </CardTitle>
        <CardBody>
          <Table>
            <thead>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {initState.colours.map((element, index) => (
                <tr key={`size-index-${index}`}>
                  <td>{index + 1}</td>
                  <td>{element.colour}</td>
                  <td>
                    <Button
                      type="danger"
                      size="small"
                      onClick={() => onColourView(element)}
                    >
                      View
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card> */}
    </Container>
  )
}
export default EditProduct
