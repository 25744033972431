import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Table,
  CardFooter,
} from 'reactstrap'
import { getCurrentUser } from 'lib/getCurrentUser'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import CustomPagnation from 'components/pagination'
import useQuery from 'hooks/useQuery'
import { sendData } from 'utli/apiService'
const Admin = () => {
  const router = useHistory()
  const headers = ['#', 'User Name', 'Role', 'Actions']
  const query = queryString.parse(router.location.search)
  let url = 'admin/getAll'
  if (query.search) url += `?keyword=${query.search}`
  const {
    response: { adminList },
    loading,
    error,
  } = useQuery(url)

  const deleteAdmin = (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete?Data cannot be recovered.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const response = await sendData(`admin/delete/${id}`)
            if (response) {
              window.location.reload()
            }
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    })
  }

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Admins</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  className="btn-round btn-icon"
                  color="success"
                  size="sm"
                  href={`${router.location.pathname}`}
                >
                  <span className="btn-inner--text">View All</span>
                </Button>
                {['admin', 'super_admin'].includes(getCurrentUser().role) && (
                  <Button
                    className="btn-round btn-icon"
                    color="primary"
                    onClick={(e) => router.push('/admin/store')}
                    size="sm"
                  >
                    <span className="btn-inner--icon mr-1">
                      <i className="fas fa-plus" />
                    </span>
                    <span className="btn-inner--text">Add New</span>
                  </Button>
                )}
              </Col>
            </Row>
          </CardTitle>
          <div className="d-flex justify-content-end">
            <span>Total {adminList.total} admin</span>
          </div>
          <Table responsive className="items-center">
            <thead>
              <tr>
                {headers.map((element, index) => {
                  return (
                    <th
                      className=" sort"
                      data-sort={element}
                      scope="col"
                      key={`admin-account-new-header-${index}`}
                    >
                      {element}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {adminList.data.map((element, index) => {
                return (
                  <tr key={`admin-account-list-${element.id}`}>
                    <td>{index + 1}</td>
                    <td>{element.username}</td>
                    <td>{element.role}</td>
                    <td>
                      {['admin', 'super_admin'].includes(
                        getCurrentUser().role
                      ) && (
                        <div>
                          <Button
                            size="sm"
                            className="btn-icon-only"
                            color="success"
                            onClick={() => {
                              // console.log(element)
                              router.push(`/admin/update/${element.id}`)
                            }}
                          >
                            <span className="btn-inner--icon">
                              <i className="fas fa-pen" />
                            </span>
                          </Button>
                          <Button
                            size="sm"
                            className="btn-icon-only"
                            color="danger"
                            onClick={() => deleteAdmin(element.id)}
                          >
                            <span className="btn-inner--icon">
                              <i className="fas fa-trash" />
                            </span>
                          </Button>
                        </div>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </CardBody>
        {adminList.data.length > 20 && (
          <CardFooter className="d-flex justify-content-end">
            <CustomPagnation></CustomPagnation>
          </CardFooter>
        )}
      </Card>
    </>
  )
}
export default Admin
