import React from 'react'
import { useHistory } from 'react-router-dom'
import { confirmAlert } from 'react-confirm-alert'
import {
  CardBody,
  Card,
  CardTitle,
  Col,
  Row,
  Button,
  CardFooter,
  Table,
} from 'reactstrap'
import queryString from 'query-string'
import useQuery from 'hooks/useQuery'
import CustomPagnation from 'components/pagination'
import { sendData } from 'utli/apiService'
import { getReadableDateDisplay } from 'utli/convertToHumanReadableTime'
import { getCurrentUser } from 'lib/getCurrentUser'
function Nofification() {
  const router = useHistory()
  const headers = ['#', 'Image', 'Title', 'Actions']
  const query = queryString.parse(router.location.search)
  let url = 'notifications'
  if (query.search) url += `?keyword=${query.search}`
  const {
    response: { notilist },
    loading,
    error,
  } = useQuery(url)
  const deleteData = async (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete? Data cannot be recovered.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const response = await sendData(`notifications/delete/${id}`)
            if (response) window.location.reload()
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    })
  }

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <Card>
      <CardTitle className="pt-3 pl-4 pr-4">
        <Row>
          <Col xs="6">
            <h3 className="mb-0">Annoucements</h3>
          </Col>
          <Col className="text-right" xs="6">
            {getCurrentUser().role !== 'staff' && (
              <Button
                className="btn-round btn-icon"
                color="primary"
                onClick={(e) => router.push('/notification/create')}
                size="sm"
              >
                <span className="btn-inner--icon mr-1">
                  <i className="fas fa-plus" />
                </span>
                <span className="btn-inner--text">Add New</span>
              </Button>
            )}
          </Col>
        </Row>
      </CardTitle>
      <CardBody>
        <Table responsive className="items-center">
          <thead>
            <tr>
              {headers.map((element, index) => {
                return (
                  <th
                    className=" sort"
                    data-sort={element}
                    scope="col"
                    key={`user-account-new-header-${index}`}
                  >
                    {element}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {notilist.data.map((noti, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>
                  {<img src={noti.image} width="70" height="50" contain />}
                </td>
                <td>{noti.title}</td>
                <td>
                  {getCurrentUser().role !== 'staff' && (
                    <>
                      <Button
                        className="btn-round btn-icon"
                        color="success"
                        onClick={(e) =>
                          router.push(`/notification/edit/${noti.id}`)
                        }
                        size="sm"
                      >
                        <span className="btn-inner--text">Edit</span>
                      </Button>
                      <Button
                        className="btn-round btn-icon"
                        color="danger"
                        onClick={() => deleteData(noti.id)}
                        size="sm"
                      >
                        <span className="btn-inner--text">Delete</span>
                      </Button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
      {notilist.data.length > 20 && (
        <CardFooter className="d-flex justify-content-end">
          <CustomPagnation></CustomPagnation>
        </CardFooter>
      )}
    </Card>
  )
}

export default Nofification
