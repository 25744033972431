import React from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import {
  Card,
  CardBody,
  CardTitle,
  Table,
  CardFooter,
  Row,
  Col,
  Button,
} from 'reactstrap'
import Select from 'react-select'
import ExcelExport from '../../components/excelExport'
import queryString from 'query-string'
import CustomPagnation from 'components/pagination'
import useQuery from 'hooks/useQuery'
import { getData } from 'utli/apiService'
import { paginationLimit } from 'variables/limits'
function Stock() {
  const router = useHistory()
  const [exportedData, setExportedData] = React.useState([])
  const [exportedLoading, setExportedLoading] = React.useState(false)
  const query = queryString.parse(router.location.search)

  const { response: productResponse, loading: productLoading } =
    useQuery('products')
  const products = productResponse?.productlist?.data?.map((element) => ({
    value: element.id,
    label: `${element.name} (${element.colour}) (${element.size})`,
  }))

  const getExportedData = async () => {
    setExportedLoading(true)
    let url = 'stocks?'
    if (query.product_id) url += `&product_id=${query.product_id}&perPage=1000`
    const response = await getData(url)
    setExportedData(response.data.stocklist.data)
    setExportedLoading(false)
  }

  let url = 'stocks?'
  let page = query.page || 1
  if (query.product_id) url += `&product_id=${query.product_id}`
  if (query.page) url += `&page=${page}`
  const {
    response: { stocklist },
    loading,
    error,
  } = useQuery(url)

  if (loading | productLoading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <Card>
      <CardBody>
        <Row>
          <Col xs="6">
            <h3 className="mb-0">Stock</h3>
          </Col>

          <Col className="text-right" xs="6">
            <Button
              className="btn-round btn-icon"
              color="success"
              size="sm"
              onClick={getExportedData}
            >
              <span className="btn-inner--text">
                {exportedLoading ? 'Loading..' : 'Export Excel'}
              </span>
            </Button>
            <Button
              className="btn-round btn-icon"
              color="success"
              size="sm"
              href={`${router.location.pathname}`}
            >
              <span className="btn-inner--text">View All</span>
            </Button>
          </Col>
        </Row>
        <CardTitle>
          <Row className="mt-3 pl-2 pr-2">
            <Col md="4" sm="4">
              <label>Product</label>
              <Select
                classNamePrefix="addl-class"
                options={products}
                defaultValue={products[0]?.value}
                onChange={({ value }) => {
                  router.push(`${router.location.pathname}?product_id=${value}`)
                }}
              />
            </Col>
          </Row>
        </CardTitle>
        {exportedData.length > 0 && (
          <ExcelExport
            data={exportedData}
            fileName={'Stock'}
            columns={[
              { title: 'Product Name', value: 'name' },
              { title: 'Current Stock', value: 'stock' },
              { title: 'Buy', value: 'in_count' },
              { title: 'Sold', value: 'out_count' },
              { title: 'Issue Out', value: 'issue_out_count' },
            ]}
            customButton={
              <Button style={{ marginLeft: 20, marginBottom: 10 }} size="sm">
                Download Excel ({exportedData.length})
              </Button>
            }
          />
        )}
        <Table>
          <thead>
            <tr>
              <th>No</th>
              <th>Product Name</th>
              <th>Current Stock</th>
              <th>Buy</th>
              <th>Sold</th>
              <th>Issue Out</th>
            </tr>
          </thead>
          <tbody>
            {stocklist.data.map((element, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>
                  {element.name} ({element.size}) ({element.colour})
                </td>
                <td>{element.stock}</td>
                <td>
                  <Link to={`/arrival?product_id=${element.product_id}`}>
                    {element.in_count}
                  </Link>
                </td>
                <td>{element.out_count}</td>
                <td>{element.issue_out_count}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </CardBody>
      {stocklist.total > paginationLimit && (
        <CardFooter className="d-flex justify-content-end">
          <CustomPagnation
            pageCount={stocklist.total / paginationLimit}
          ></CustomPagnation>
        </CardFooter>
      )}
    </Card>
  )
}

export default Stock
