import { useState, useRef } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Row,
  Form,
} from 'reactstrap'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import CropperModal from 'components/cropperModal'
import { dataUrlToFile } from 'utli/fileConverter'
import { sendData } from 'utli/apiService'
import { NotificationManager } from 'react-notifications'

const CreateCategory = () => {
  const mainTypeOptions = [
    { value: 'Women', label: 'Women' },
    { value: 'Men', label: 'Men' },
    { value: 'Kids', label: 'Kids' },
    { value: 'Lifestyle', label: 'Lifestyle' },
    { value: 'Beauty', label: 'Beauty' },
  ]
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm()
  const [initState, setInitState] = useState({
    openCropper: false,
    image: null,
    croppedImage: null,
    imageError: false,
  })
  const [cropImage, setCropImage] = useState(null)
  const inputFile = useRef(null)

  const submitData = async (values) => {
    if (!cropImage) {
      setInitState({ ...initState, imageError: true })
    } else {
      const formData = new FormData()
      const imageFile = await dataUrlToFile(cropImage)
      formData.append('name', values.name)
      formData.append('main_type', values.main_type)
      formData.append('image', imageFile)
      const response = await sendData('categories/store', formData)
      if (response) {
        NotificationManager.success('Save')
        reset()
        setInitState({ ...initState, croppedImage: null })
        setCropImage(null)
      }
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(submitData)}>
        <Card>
          <CardTitle className="pt-2 pl-4 bg-info">
            <h3 className="text-white">New Category</h3>
          </CardTitle>
          <CardBody>
            <Row>
              <Col md="6" sm="12">
                <label>Name</label>
                <input
                  className="form-control"
                  {...register('name', {
                    required: 'Name is required',
                  })}
                />
                {errors.name && (
                  <span className="text-danger">{errors?.name?.message}</span>
                )}
              </Col>

              <Col md="6" sm="12">
                <label>Main Type</label>
                <Controller
                  control={control}
                  name="main_type"
                  rules={{ required: true }}
                  defaultValue={mainTypeOptions[0]?.value}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      inputRef={ref}
                      classNamePrefix="addl-class"
                      options={mainTypeOptions}
                      defaultValue={mainTypeOptions[0]?.value}
                      value={mainTypeOptions.find((c) => c.value === value)}
                      onChange={(val) => onChange(val.value)}
                    />
                  )}
                />
                {errors.main_type && (
                  <span className="text-danger">
                    {errors?.main_type?.message}
                  </span>
                )}
              </Col>

              <Col md="6" sm="12" className="pt-3">
                <label>Image</label>
                {cropImage ? (
                  <div>
                    <img
                      alt="croped result"
                      src={cropImage}
                      width="220"
                      height="350"
                      onClick={() => {
                        inputFile.current.click()
                      }}
                    />
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      inputFile.current.click()
                    }}
                    style={{
                      width: 220,
                      height: 350,
                      background: 'grey',
                      cursor: 'pointer',
                    }}
                  ></div>
                )}
                {initState.imageError && (
                  <p className="text-danger">Image is required</p>
                )}
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: 'none' }}
                  onChange={(event) => {
                    if (event.target.files[0]) {
                      setInitState({
                        ...initState,
                        openCropper: true,
                        image: URL.createObjectURL(event.target.files[0]),
                      })
                    }
                  }}
                />
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="d-flex justify-content-center">
            <Button color="default" type="submit">
              Create
            </Button>
          </CardFooter>
        </Card>
      </Form>
      {initState.openCropper && (
        <CropperModal
          isShow={initState.openCropper}
          image={initState.image}
          close={() => setInitState({ ...initState, openCropper: false })}
          setCroppedImage={async (image) => {
            setCropImage(image)
          }}
          x={4}
          y={6}
        />
      )}
    </Container>
  )
}
export default CreateCategory
