import React from 'react'
import { Card, CardBody, CardTitle, Row, Col, Button, Table } from 'reactstrap'
import useQuery from 'hooks/useQuery'
import { confirmAlert } from 'react-confirm-alert'
import { sendData } from 'utli/apiService'
import { getCurrentUser } from 'lib/getCurrentUser'
const RoyalCustomer = () => {
  const headers = ['#', 'User Name', 'Report']
  let url = 'report?'
  const {
    response: { reportList },
    loading,
    error,
  } = useQuery(url)

  const viewReport = (userName, report) => {
    confirmAlert({
      title: userName,
      message: report,
      buttons: [
        {
          label: 'Ok',
          onClick: () => {},
        },
      ],
    })
  }

  const deleteReport = (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete? Data cannot be recovered.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const response = await sendData(`report/delete/${id}`)
            // console.log(response)
            if (response) {
              window.location.reload()
            }
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    })
  }
  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">User Report</h3>
              </Col>
            </Row>
          </CardTitle>
          <Table responsive className="items-center">
            <thead>
              <tr>
                {headers.map((element, index) => {
                  return (
                    <th
                      className=" sort"
                      data-sort={element}
                      scope="col"
                      key={`user-account-new-header-${index}`}
                    >
                      {element}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {reportList.data.map((element, index) => {
                return (
                  <tr key={`user-account-list-${element.id}`}>
                    <td>{index + 1}</td>
                    <td>{element.user.name}</td>
                    {element.body.length > 50 ? (
                      <td>
                        {element.body.substring(0, 50) + ' ... '}
                        <Button
                          size="sm"
                          color="primary"
                          onClick={() =>
                            viewReport(element.user.name, element.body)
                          }
                        >
                          <span>View All</span>
                        </Button>
                      </td>
                    ) : (
                      <td>
                        {element.body}{' '}
                        <Button
                          size="sm"
                          color="primary"
                          onClick={() =>
                            viewReport(element.user.name, element.body)
                          }
                        >
                          <span>View All</span>
                        </Button>
                      </td>
                    )}
                    {/* <td>
                      {element.body.length > 50
                        ? `${element.body.substring(0, 50)}....`
                        : element.body}
                        
                    </td> */}

                    <td>
                      {['admin', 'super_admin'].includes(
                        getCurrentUser().role
                      ) && (
                        <Button
                          size="sm"
                          className="btn-icon-only"
                          color="danger"
                          onClick={() => deleteReport(element.id)}
                        >
                          <span className="btn-inner--icon">
                            <i className="fas fa-trash" />
                          </span>
                        </Button>
                      )}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  )
}
export default RoyalCustomer
