import React, { useState, useEffect } from 'react'
import {
  CardHeader,
  Card,
  CardBody,
  Table,
  CardFooter,
  Button,
} from 'reactstrap'
import useQuery from 'hooks/useQuery'
import { NotificationManager } from 'react-notifications'
import { sendData } from 'utli/apiService'
import { getCurrentUser } from 'lib/getCurrentUser'
function EditOrder(props) {
  const {
    response: { orderInfo },
    loading,
    error,
  } = useQuery(`orders/detail/${props.match.params.id}`)

  const [orders, setOrders] = useState([])
  const [initState, setInitState] = useState({
    loading: false,
  })

  const getTotal = () => {
    let total = 0
    orders.forEach(
      (element) =>
        (total += parseInt(element.product.price) * parseInt(element.qty))
    )
    return total
  }
  const validate = () => {
    let valid = true
    orders.forEach((element) => {
      if (element.qty.length === 0) valid = false
    })
    return valid
  }
  const getDeleteIDs = () => {
    let deletedIDs = []
    orderInfo.order_details.forEach((element) => {
      if (!orders.find((order) => order.id === element.id)) {
        deletedIDs.push({ detail_id: element.id })
      }
    })
    return deletedIDs
  }
  const confirmOrder = async () => {
    if (validate()) {
      const response = await sendData('orders/updateOrder', {
        order_id: props.match.params.id,
        cart: orders.map((element) => ({
          product_id: element.product.id,
          qty: element.qty,
          detail_id: element.id,
        })),
        deletecart: getDeleteIDs(),
      })
      if (response) {
        NotificationManager.success('Updated')
        //  window.location.reload()
      }
    } else {
      NotificationManager.error('Invalid fields')
    }
  }

  const deleteData = (id) => {
    setOrders(orders.filter((element) => element.id !== id))
  }
  const cancelOrder = async () => {
    setInitState({ ...initState, loading: true })
    const response = await sendData('orders/cancel', {
      id: props.match.params.id,
    })
    if (response) {
      setInitState({ ...initState, loading: false })
      NotificationManager.success('Updated')
      window.location.reload()
    }
  }

  useEffect(() => {
    if (!loading) {
      setOrders(orderInfo.order_details)
    }
  }, [loading, orderInfo])

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <div>
      <Card>
        <CardHeader className="bg-default pl-4 pr-4">
          <h4 className="text-white">Order Info</h4>
          <div className="d-flex justify-content-between">
            <h5 className="text-white">Username : {orderInfo.user.name}</h5>
            <h5 className="text-white">Phone : {orderInfo.user.phone}</h5>
          </div>
          <div className="d-flex justify-content-end">
            <h5 className="text-white">
              Date :{orderInfo.created_at.substr(0, 10)}
            </h5>
          </div>
        </CardHeader>
        <CardBody>
          <Table>
            <thead>
              <tr>
                <td>No</td>
                <td style={{ width: '30%' }}>Product</td>
                <td>Size</td>
                <td>Colour</td>
                <td>Price</td>
                <td style={{ width: '15%' }}>Qty</td>
                <td className="d-flex justify-content-end">Total</td>
              </tr>
            </thead>
            <tbody>
              {orders.map((element, index) => (
                <tr key={`order-count-${index}`}>
                  <td>{index + 1}</td>
                  <td>
                    {element.product.name} ({element.product.size}) (
                    {element.product.colour})
                  </td>
                  <td>{element.product.size}</td>
                  <td>{element.product.colour}</td>
                  <td>{element.price}</td>
                  <td>
                    <div className="d-flex justify-content-between">
                      <input
                        className="form-control"
                        style={{ width: '60%' }}
                        type="number"
                        value={element.qty}
                        min={1}
                        onChange={(e) => {
                          let newArr = [...orders]
                          newArr[index].qty = e.target.value
                          setOrders(newArr)
                        }}
                      />
                      <Button
                        className="btn-sm btn-danger"
                        onClick={() => deleteData(element.id)}
                      >
                        X
                      </Button>
                    </div>
                  </td>
                  <td className="d-flex justify-content-end">
                    {element.product.price * element.qty}
                  </td>
                </tr>
              ))}

              <tr>
                <td colSpan="6"></td>
                <td className="d-flex justify-content-end">{getTotal()}</td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
        {getCurrentUser().role !== 'editor' && (
          <CardFooter className="d-flex justify-content-end">
            {parseInt(orderInfo.status) === 1 && (
              <>
                <Button
                  className="btn-sm bg-success text-white"
                  onClick={confirmOrder}
                  disabled={initState.loading}
                >
                  Confirm
                </Button>
                <Button
                  className="btn-sm bg-danger text-white"
                  onClick={cancelOrder}
                  disabled={initState.loading}
                >
                  Cancel
                </Button>
              </>
            )}
            {parseInt(orderInfo.status) === 2 && (
              <h4 className="text-info">Status : Confirm</h4>
            )}
            {parseInt(orderInfo.status) === 3 && (
              <h4 className="text-danger">Status : Cancel</h4>
            )}
          </CardFooter>
        )}
      </Card>
    </div>
  )
}

export default EditOrder
