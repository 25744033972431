import { useState } from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Button,
  Form,
} from 'reactstrap'
import { NotificationManager } from 'react-notifications'
import { useForm } from 'react-hook-form'
import { sendData } from 'utli/apiService'
import useQuery from 'hooks/useQuery'
const EditUserAccount = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [initState, setInitState] = useState({
    submitLoading: false,
  })

  const submitData = async (values) => {
    values.id = props.match.params.id
    setInitState({ ...initState, submitLoading: true })
    const response = await sendData('user/update', values)
    if (response) {
      NotificationManager.success('Saved')
    }
    setInitState({ ...initState, submitLoading: false })
    //window.location.reload()
  }
  const {
    response: { userInfo },
    loading,
    error,
  } = useQuery(`user/detail/${props.match.params.id}`)
  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col md="4">
              <CardTitle className="d-flex justify-content-between">
                <h2 className="mt-2">Edit User Account</h2>
              </CardTitle>
              <Form onSubmit={handleSubmit(submitData)}>
                <FormGroup className="submit-form">
                  <label htmlFor="userNameInput">User Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="userNameInput"
                    {...register('name', {
                      required: 'Name is required',
                    })}
                    defaultValue={userInfo.name}
                  ></input>
                </FormGroup>
                {errors.name && (
                  <span className="text-danger">{errors?.name?.message}</span>
                )}
                <FormGroup className="submit-form">
                  <label htmlFor="phoneInput">Phone</label>
                  <input
                    type="tel"
                    className="form-control"
                    id="phoneInput"
                    {...register('phone', {
                      required: 'Phone is required',
                    })}
                    defaultValue={userInfo.phone}
                  ></input>
                  {errors.phone && (
                    <span className="text-danger">
                      {errors?.phone?.message}
                    </span>
                  )}
                </FormGroup>
                <FormGroup className="submit-form">
                  <label htmlFor="emailInput">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="emailInput"
                    {...register('email', {
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'invalid email address',
                      },
                    })}
                    defaultValue={userInfo.email}
                  ></input>
                  {errors.email && (
                    <span className="text-danger">
                      {errors?.email?.message}
                
                    </span>
                  )}
                </FormGroup>
                <FormGroup className="submit-form">
                  <label htmlFor="addressInput">Address</label>
                  <input
                    type="tel"
                    className="form-control"
                    id="addressInput"
                    {...register('address', {
                      required: 'Address is required',
                    })}
                    defaultValue={userInfo.address}
                  ></input>
                  {errors.address && (
                    <span className="text-danger">
                      {errors?.address?.message}
                    </span>
                  )}
                </FormGroup>
                <FormGroup className="submit-form">
                  <label htmlFor="viberInput">Viber</label>
                  <input
                    type="tel"
                    className="form-control"
                    id="viberInput"
                    {...register('viber', {})}
                    defaultValue={userInfo.viber}
                  ></input>
                </FormGroup>

                <FormGroup className="submit-form__btn-section">
                  <Button
                    type="submit"
                    color="default"
                    disabled={initState.submitLoading}
                  >
                    Edit User
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}
export default EditUserAccount
