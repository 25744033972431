import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { confirmAlert } from 'react-confirm-alert'
import queryString from 'query-string'
import useQuery from 'hooks/useQuery'
import Select from 'react-select'
import {
  Card,
  CardTitle,
  Row,
  Col,
  Button,
  CardBody,
  Table,
  CardFooter,
} from 'reactstrap'
import { sendData } from 'utli/apiService'
import { isOverOneDay, getReadableDateDisplay } from 'lib/dateFormat'
import { paginationLimit } from 'variables/limits'
import CustomPagnation from 'components/pagination'
import ExcelExport from '../../components/excelExport'
import { getData } from 'utli/apiService'
import { getCurrentUser } from 'lib/getCurrentUser'

function ItemArrival() {
  const router = useHistory()
  const [exportedData, setExportedData] = useState([])
  const [exportedLoading, setExportedLoading] = useState(false)
  const query = queryString.parse(router.location.search)

  const { response: productResponse, loading: productLoading } =
    useQuery('products')
  const products = productResponse?.productlist?.data?.map((element) => ({
    value: element.id,
    label: `${element.name} (${element.colour}) (${element.size})`,
  }))

  let url = 'purchases?'
  let page = query.page || 1
  if (query.product_id) url += `&product_id=${query.product_id}`
  if (query.page) url += `&page=${page}`
  const {
    response: { purchaselist },
    loading,
    error,
  } = useQuery(url)

  const getSubTotal = () => {
    let subTotal = 0
    purchaselist.data.map((element) => {
      subTotal += parseInt(element.price * element.quantity)
    })
    return subTotal
  }

  const deleteData = (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete? Data cannot be recovered.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const response = await sendData(`purchases/delete/${id}`)
            if (response) {
              window.location.reload()
            }
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    })
  }

  const getExportedData = async () => {
    setExportedLoading(true)
    let url = 'purchases?'
    if (query.product_id) url += `&product_id=${query.product_id}&perPage=1000`
    const response = await getData(url)

    setExportedData(
      response.data.purchaselist.data.map((element) => {
        element.name = element.product?.name
        return element
      })
    )
    setExportedLoading(false)
  }
  console.log(exportedData)

  if (loading | productLoading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <Card>
      <CardBody>
        <CardTitle className="border-0 p-3">
          <Row>
            <Col xs="6">
              <h3 className="mb-0">Item Arrival</h3>
            </Col>
            <Col className="text-right" xs="6">
              <Button
                className="btn-round btn-icon"
                color="success"
                size="sm"
                onClick={getExportedData}
              >
                <span className="btn-inner--text">
                  {exportedLoading ? 'Loading..' : 'Export Excel'}
                </span>
              </Button>
              <Button
                className="btn-round btn-icon"
                color="success"
                size="sm"
                href={`${router.location.pathname}`}
              >
                <span className="btn-inner--text">View All</span>
              </Button>
              {getCurrentUser().role !== 'editor' && (
                <Button
                  className="btn-round btn-icon"
                  color="primary"
                  onClick={(e) => router.push('/arrival/create')}
                  size="sm"
                >
                  <span className="btn-inner--icon mr-1">
                    <i className="fas fa-plus" />
                  </span>
                  <span className="btn-inner--text">Add New</span>
                </Button>
              )}
            </Col>
          </Row>
        </CardTitle>
        <CardTitle>
          <Row className="mt-3 pl-2 pr-2">
            <Col md="4" sm="4">
              <label>Product</label>
              <Select
                classNamePrefix="addl-class"
                options={products}
                defaultValue={products[0]?.value}
                onChange={({ value }) => {
                  router.push(`${router.location.pathname}?product_id=${value}`)
                }}
              />
            </Col>
          </Row>
        </CardTitle>

        <div className="d-flex justify-content-end">
          {exportedData.length > 0 && (
            <ExcelExport
              data={exportedData}
              fileName={'itemArrival'}
              columns={[
                { title: 'Product', value: 'name' },
                { title: 'Buy Price', value: 'price' },
                { title: 'Quantity', value: 'quantity' },
                { title: 'Total', value: 'total' },
              ]}
              customButton={
                <Button style={{ marginLeft: 20, marginBottom: 10 }} size="sm">
                  Download Excel ({exportedData.length})
                </Button>
              }
            />
          )}
          <span>Total {purchaselist.total} purchases</span>
        </div>
        <Table>
          <thead>
            <tr>
              <th>No</th>
              <th>Product</th>
              <th>Buy Price</th>
              <th>Quantity</th>
              <th style={{ textAlign: 'right' }}>Total</th>
              <th>Inserted At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {purchaselist.data.map((element, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>
                  {element.product?.name} ({element.product?.colour}) (
                  {element.product?.size})
                </td>
                <td>{element.price}</td>
                <td>{element.quantity}</td>
                <td align="right">{element.price * element.quantity}</td>
                <td>{getReadableDateDisplay(element.created_at)}</td>
                <td>
                  {getCurrentUser().role !== 'editor' && (
                    <Button
                      size="sm"
                      className="btn-icon-only"
                      color="danger"
                      disabled={!isOverOneDay(element.created_at)}
                      onClick={() => deleteData(element.id)}
                    >
                      <span className="btn-inner--icon">
                        <i className="fas fa-trash" />
                      </span>
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={4}>Sub Total :</td>
              <td align="right">{getSubTotal()}</td>
            </tr>
          </tfoot>
        </Table>
      </CardBody>
      {purchaselist.total > paginationLimit && (
        <CardFooter className="d-flex justify-content-end">
          <CustomPagnation
            pageCount={purchaselist.total / paginationLimit}
          ></CustomPagnation>
        </CardFooter>
      )}
    </Card>
  )
}

export default ItemArrival
