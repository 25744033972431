import { useState } from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Button,
  Form,
} from 'reactstrap'
import { useForm, Controller } from 'react-hook-form'
import Select from 'react-select'
import { sendData } from 'utli/apiService'
import { NotificationManager } from 'react-notifications'

const CreateAdminAccount = () => {
  const roles = [
    {
      label: 'admin',
      value: 'admin',
    },
    {
      label: 'super_admin',
      value: 'super_admin',
    },
    {
      label: 'editor',
      value: 'editor',
    },
    {
      label: 'staff',
      value: 'staff',
    },
  ]
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm()

  const [initState, setInitState] = useState({
    submitLoading: false,
  })

  const submitData = async (values) => {
    setInitState({ ...initState, submitLoading: true })
    const response = await sendData('admin/store', values)

    console.log(response)
    if (response) {
      NotificationManager.success('Saved')
    }
    setInitState({ ...initState, submitLoading: false })
    reset()
  }

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col md="4">
              <CardTitle className="d-flex justify-content-between">
                <h2 className="mt-2">Create Admin Account</h2>
              </CardTitle>
              <Form onSubmit={handleSubmit(submitData)}>
                <FormGroup className="submit-form">
                  <label htmlFor="adminNameInput">User Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="adminNameInput"
                    {...register('username', {
                      required: 'Userame is required',
                    })}
                  ></input>
                </FormGroup>
                {errors.username && (
                  <span className="text-danger">{errors?.name?.message}</span>
                )}

                <FormGroup className="submit-form">
                  <label htmlFor="passwordInput">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="passwordInput"
                    {...register('password', {
                      required: 'Password is required',
                    })}
                  ></input>
                  {errors.password && (
                    <span className="text-danger">
                      {errors?.password?.message}
                    </span>
                  )}
                </FormGroup>

                <FormGroup className="submit-form">
                  <label htmlFor="roleInput">Role</label>
                  <Controller
                    control={control}
                    name="role"
                    rules={{ required: true }}
                    defaultValue={roles[0]?.value}
                    render={({ field: { onChange, value, ref } }) => (
                      <Select
                        inputRef={ref}
                        classNamePrefix="addl-class"
                        options={roles}
                        defaultValue={roles[0]?.value}
                        value={roles.find((c) => c.value === value)}
                        onChange={(val) => onChange(val.value)}
                      />
                    )}
                  />
                  {errors.role && (
                    <span className="text-danger">{errors?.role?.message}</span>
                  )}
                </FormGroup>

                <FormGroup className="submit-form__btn-section">
                  <Button
                    type="submit"
                    color="default"
                    disabled={initState.submitLoading}
                  >
                    Add new admin
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}
export default CreateAdminAccount
