import { useState } from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Button,
  Form,
} from 'reactstrap'
import { useForm } from 'react-hook-form'
import useQuery from 'hooks/useQuery'
import { sendData } from 'utli/apiService'
import { NotificationManager } from 'react-notifications'
import { getCurrentUser } from 'lib/getCurrentUser'

const ContactInfo = () => {
  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm()
  const {
    response: { info },
    loading,
    error,
  } = useQuery('contactinfos')

  const [initState, setInitState] = useState({
    submitLoading: false,
  })

  const submitData = async (values) => {
    setInitState({ ...initState, submitLoading: true })
    const response = await sendData('contactinfos/store', values)
    if (response) {
      NotificationManager.success('Saved')
    }
    setInitState({ ...initState, submitLoading: false })
  }

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col md="4">
              <CardTitle className="d-flex justify-content-between">
                <h2 className="mt-2">Contact Info</h2>
              </CardTitle>
              <Form onSubmit={handleSubmit(submitData)}>
                <FormGroup className="submit-form">
                  <label htmlFor="phoneInput">Phone</label>
                  <input
                    type="text"
                    className="form-control"
                    id="phoneInput"
                    {...register('phone', {
                      required: 'Phone is required',
                    })}
                    defaultValue={info?.phone}
                  ></input>
                  {errors.phone && (
                    <span className="text-danger">
                      {errors?.phone?.message}
                    </span>
                  )}
                </FormGroup>

                <FormGroup className="submit-form">
                  <label htmlFor="viberInput">Viber Number</label>
                  <input
                    type="text"
                    className="form-control"
                    id="viberInput"
                    {...register('viber', {
                      required: 'Viber Number is required',
                    })}
                    defaultValue={info?.viber}
                  ></input>
                  {errors.viber && (
                    <span className="text-danger">
                      {errors?.viber?.message}
                    </span>
                  )}
                </FormGroup>

                <FormGroup className="submit-form">
                  <label htmlFor="telegramInput">Telegram User Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="telegramInput"
                    {...register('telegram', {
                      required: 'Telegram Number is required',
                    })}
                    defaultValue={info?.telegram}
                  ></input>
                  {errors.telegram && (
                    <span className="text-danger">
                      {errors?.telegram?.message}
                    </span>
                  )}
                </FormGroup>

                <FormGroup className="submit-form">
                  <label htmlFor="facebookInput">Facebook</label>
                  <input
                    type="text"
                    className="form-control"
                    id="facebookInput"
                    {...register('facebook', {
                      required: 'Facebook is required',
                    })}
                    defaultValue={info?.facebook}
                  ></input>
                  {errors.facebook && (
                    <span className="text-danger">
                      {errors?.facebook?.message}
                    </span>
                  )}
                </FormGroup>

                <FormGroup className="submit-form">
                  <label htmlFor="addressInput">Address</label>
                  <textarea
                    className="form-control"
                    placeholder="Write  your address here ..."
                    rows="5"
                    {...register('address', {
                      required: 'Address is required',
                    })}
                    defaultValue={info?.address}
                  />
                  {errors.address && (
                    <span className="text-danger">
                      {errors?.address?.message}
                    </span>
                  )}
                </FormGroup>

                {getCurrentUser().role === 'admin' ||
                  (getCurrentUser().role === 'super_admin' && (
                    <FormGroup className="submit-form__btn-section">
                      <Button
                        type="submit"
                        color="default"
                        disabled={initState.submitLoading}
                      >
                        {info.phone ? 'Edit' : 'Save'}
                      </Button>
                    </FormGroup>
                  ))}
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}
export default ContactInfo
