import React, { useState } from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Table,
  Button,
  CardFooter,
  Col,
} from 'reactstrap'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import queryString from 'query-string'
import useQuery from 'hooks/useQuery'
import CustomPagnation from 'components/pagination'
import Select from 'react-select'
import { paginationLimit } from 'variables/limits'
import ExcelExport from 'components/excelExport'
function ProductList() {
  const router = useHistory()
  const [exportedData, setExportedData] = useState([])
  const [exportedLoading, setExportedLoading] = useState(false)
  const query = queryString.parse(router.location.search)
  let url = 'products?'
  let page = query.page || 1
  if (query.search) url += `&keyword=${query.search}`
  if (query.category_id) url += `&category_id=${query.category_id}`
  if (query.page) url += `&page=${page}`

  url += `&groupBy=true`

  const {
    response: { productlist },
    loading,
    error,
  } = useQuery(url)

  const {
    response: { data: categorylists },
    loading: categoryLoading,
    error: categoryErr,
  } = useQuery('categories/getList')
  const categories = categorylists?.map((element) => ({
    value: element.id,
    label: element.name + ` (${element.main_type})`,
  }))

  const getExportedData = async () => {
    setExportedLoading(true)
    const products = [...productlist.data]
    setExportedData(
      products.map((element) => {
        element.category = element.category?.name
        return element
      })
    )
    setExportedLoading(false)
  }

  if (loading | categoryLoading) return <h3>Loading...</h3>
  if (error | categoryErr) return <h3>{error.response.message}</h3>
  return (
    <Card>
      <CardTitle className="pt-3 pl-4">
        <div className="d-flex justify-content-between pr-3">
          <h2>Products</h2>
          <span>Total {productlist.total} products</span>
        </div>

        <Row className="mt-3 pl-2 pr-2">
          <Col md="4" sm="4">
            <label>Category</label>
            <Select
              classNamePrefix="addl-class"
              options={categories}
              defaultValue={categories[0]?.value}
              onChange={({ value }) => {
                router.push(`${router.location.pathname}?category_id=${value}`)
              }}
            />
          </Col>
          <Col md="4" sm="4"></Col>
          <Col md="4" sm="3">
            <br />
            <Button className="mt-2" color="success" onClick={getExportedData}>
              <span className="btn-inner--text">
                {exportedLoading ? 'Loading..' : 'Export Excel'}
              </span>
            </Button>

            <Button
              href={`${router.location.pathname}/create`}
              className="mt-2"
              color="primary"
            >
              Create New
            </Button>
            <Button
              href={`${router.location.pathname}`}
              className="mt-2"
              color="primary"
            >
              View All
            </Button>
          </Col>
        </Row>
      </CardTitle>

      <CardBody>
        <Row className="justify-content-md-sstart justify-content-center">
          <div className="d-flex justify-content-end">
            {exportedData.length > 0 && (
              <ExcelExport
                data={exportedData}
                fileName={'productList'}
                columns={[
                  { title: 'Name', value: 'name' },
                  { title: 'Price', value: 'price' },
                  { title: 'Category', value: 'category' },
                ]}
                customButton={
                  <Button
                    style={{ marginLeft: 20, marginBottom: 10 }}
                    size="sm"
                  >
                    Download Excel ({exportedData.length})
                  </Button>
                }
              />
            )}
          </div>
          <Table>
            <thead>
              <tr>
                <th>No</th>
                <th>Images</th>
                <th>Name</th>
                <th>Price</th>
                <th>Category</th>
                <th>In Stock</th>
                <th>Actions</th>
              </tr>
            </thead>

            {productlist.data.map((element, index) => (
              <tr>
                <td> {index + 1 + page * paginationLimit - paginationLimit}</td>
                <td>
                  <img
                    alt="product list"
                    src={element.productimages[0].image}
                    width="50"
                    height="80"
                  />
                </td>
                <td> {element.name}</td>
                <td> {element.price}</td>
                <td>{element.category?.name}</td>
                <td>
                  <Link to={`/stocks?product_id=${element.id}`}>
                    {element.stock}
                  </Link>
                </td>
                <td>
                  <Button
                    color="primary"
                    className="btn btn-sm"
                    onClick={() => {
                      router.push(`product/edit/${element.id}`)
                    }}
                  >
                    View
                  </Button>
                </td>
              </tr>
            ))}
          </Table>
        </Row>
      </CardBody>
      {productlist.total > 10 && (
        <CardFooter className="d-flex justify-content-end">
          <CustomPagnation
            pageCount={productlist.total / paginationLimit}
          ></CustomPagnation>
        </CardFooter>
      )}
    </Card>
  )
}
export default ProductList
