import { useState } from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Button,
  Form,
} from 'reactstrap'
import { useForm } from 'react-hook-form'
import { sendData } from 'utli/apiService'
import { NotificationManager } from 'react-notifications'

const CreateUserAccount = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const [initState, setInitState] = useState({
    submitLoading: false,
  })

  const submitData = async (values) => {
    setInitState({ ...initState, submitLoading: true })
    const response = await sendData('user/register', values)
    if (response) {
      NotificationManager.success('Saved')
    }
    setInitState({ ...initState, submitLoading: false })
    reset()
  }

  return (
    <>
      <Card>
        <CardBody>
          <Row>
            <Col md="4">
              <CardTitle className="d-flex justify-content-between">
                <h2 className="mt-2">Create User Account</h2>
              </CardTitle>
              <Form onSubmit={handleSubmit(submitData)}>
                <FormGroup className="submit-form">
                  <label htmlFor="userNameInput">User Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="userNameInput"
                    {...register('name', {
                      required: 'Name is required',
                    })}
                  ></input>
                </FormGroup>
                {errors.name && (
                  <span className="text-danger">{errors?.name?.message}</span>
                )}
                <FormGroup className="submit-form">
                  <label htmlFor="phoneInput">Phone</label>
                  <input
                    type="tel"
                    className="form-control"
                    id="phoneInput"
                    {...register('phone', {
                      required: 'Phone is required',
                    })}
                  ></input>
                  {errors.phone && (
                    <span className="text-danger">
                      {errors?.phone?.message}
                    </span>
                  )}
                </FormGroup>
                <FormGroup className="submit-form">
                  <label htmlFor="emailInput">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="emailInput"
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      },
                    })}
                  ></input>
                  {errors.email && (
                    <span className="text-danger">
                      {errors?.email?.message}
                    </span>
                  )}
                </FormGroup>
                <FormGroup className="submit-form">
                  <label htmlFor="addressInput">Address</label>
                  <input
                    type="tel"
                    className="form-control"
                    id="addressInput"
                    {...register('address', {
                      required: 'Address is required',
                    })}
                  ></input>
                  {errors.address && (
                    <span className="text-danger">
                      {errors?.address?.message}
                    </span>
                  )}
                </FormGroup>
                <FormGroup className="submit-form">
                  <label htmlFor="viberInput">Viber</label>
                  <input
                    type="tel"
                    className="form-control"
                    id="viberInput"
                    {...register('viber', {})}
                  ></input>
                </FormGroup>
                <FormGroup className="submit-form">
                  <label htmlFor="passwordInput">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="passwordInput"
                    {...register('password', {
                      required: 'Password is required',
                    })}
                  ></input>
                  {errors.password && (
                    <span className="text-danger">
                      {errors?.password?.message}
                    </span>
                  )}
                </FormGroup>

                <FormGroup className="submit-form__btn-section">
                  <Button
                    type="submit"
                    color="default"
                    disabled={initState.submitLoading}
                  >
                    Add new user
                  </Button>
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  )
}
export default CreateUserAccount
