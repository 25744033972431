import { useState, useRef } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Row,
  Form,
} from 'reactstrap'
import Select from 'react-select'
import { NotificationManager } from 'react-notifications'
import draftToHtml from 'draftjs-to-html'
import { convertToRaw } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import useQuery from 'hooks/useQuery'
import { useForm, Controller } from 'react-hook-form'
import CropperModal from 'components/cropperModal'
import { dataUrlToFile } from 'utli/fileConverter'
import { sendData } from 'utli/apiService'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import './create.scss'
const CreateProduct = () => {
  const [initState, setInitState] = useState({
    openCropper: false,
    image: null,
    croppedImages: [],
    submitLoading: false,
    imageError: false,
    description: '',
  })
  const inputFile = useRef(null)

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm()

  const submitData = async (values) => {
    if (initState.croppedImages.length === 0) {
      setInitState({ ...initState, imageError: true })
    } else {
      setInitState({ ...initState, submitLoading: true })
      const formData = new FormData()
      formData.append('name', values.name)
      formData.append('price', values.price)
      formData.append('code', values.code)
      formData.append('colour', values.colour)
      formData.append('size', values.size)
      formData.append('description', initState.description)
      formData.append('least_amount', values.least_amount)
      formData.append('category_id', values.category_id)
      let counter = 0
      for (let img of initState.croppedImages) {
        const imageFile = await dataUrlToFile(img)
        formData.append(`images[${counter}]`, imageFile)
        counter++
      }
      const response = await sendData('products/store', formData)
      if (response) {
        NotificationManager.success('Save')
        setInitState({
          ...initState,
          submitLoading: false,
          imageError: false,
          croppedImages: [],
        })
        window.location.reload()
      }
    }
  }

  const { response, loading, error } = useQuery('categories/getList')
  const categories = response?.data?.map((element) => ({
    value: element.id,
    label: element.name + ` (${element.main_type})`,
  }))

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <Container>
      <Form onSubmit={handleSubmit(submitData)}>
        <Card>
          <CardTitle className="pt-2 pl-4 bg-info">
            <h3 className="text-white">New Product</h3>
          </CardTitle>
          <CardBody>
            <Row>
              <Col md="6" sm="12">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  {...register('name', {
                    required: 'Name is required',
                  })}
                />
                {errors.name && (
                  <span className="text-danger">{errors?.name?.message}</span>
                )}
              </Col>
              <Col md="6" sm="12">
                <label>Code</label>
                <input
                  type="text"
                  className="form-control"
                  {...register('code', {
                    required: 'Code is required',
                  })}
                />
                {errors.code && (
                  <span className="text-danger">{errors?.code?.message}</span>
                )}
              </Col>
              <Col sm="12" style={{ paddingBottom: '20px' }}>
                <label className="mt-4">Category</label>
                <Controller
                  control={control}
                  name="category_id"
                  rules={{ required: true }}
                  defaultValue={categories[0]?.value}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      inputRef={ref}
                      classNamePrefix="addl-class"
                      options={categories}
                      defaultValue={categories[0]?.value}
                      value={categories.find((c) => c.value === value)}
                      onChange={(val) => onChange(val.value)}
                    />
                  )}
                />
                {errors.category_id && (
                  <span className="text-danger">
                    {errors?.category_id?.message}
                  </span>
                )}
              </Col>
              <Col md="6" sm="12">
                <label>Colour</label>
                <input
                  type="color"
                  className="form-control"
                  {...register('colour', {
                    required: 'colour is required',
                  })}
                />
                {errors.colour && (
                  <span className="text-danger">{errors?.colour?.message}</span>
                )}
              </Col>
              <Col md="6" sm="12">
                <label>Size</label>
                <input
                  type="text"
                  className="form-control"
                  {...register('size', {
                    required: 'size is required',
                  })}
                />
                {errors.size && (
                  <span className="text-danger">{errors?.size?.message}</span>
                )}
              </Col>

              <Col md="6" sm="12" className="mt-3">
                <label>Price</label>
                <input
                  type="number"
                  className="form-control"
                  {...register('price', {
                    required: 'Price is required',
                  })}
                />
                {errors.price && (
                  <span className="text-danger">{errors?.price?.message}</span>
                )}
              </Col>
              <Col sm="12" className="mt-3 editor-main">
                <label>Description</label>
                <Editor
                  toolbarClassName="editor-toolbar"
                  wrapperClassName="editor-wrapper"
                  editorClassName="editor"
                  toolbar={{ options: ['list'] }}
                  name="coordination"
                  onEditorStateChange={(editorState) => {
                    const rawContentState = convertToRaw(
                      editorState.getCurrentContent()
                    )
                    const markup = draftToHtml(rawContentState)
                    setInitState({ ...initState, description: markup })
                  }}
                />
              </Col>
              <Col sm="12" className="pt-3 ">
                <label>Images</label>
                <div className="d-flex" style={{ overflow: 'auto' }}>
                  <div
                    className="d-flex justify-content-center align-items-center text-white"
                    style={{ width: 220, height: 350, background: 'grey' }}
                    onClick={() => {
                      inputFile.current.click()
                    }}
                  >
                    Upload
                  </div>
                  <input
                    type="file"
                    id="file"
                    ref={inputFile}
                    style={{ display: 'none' }}
                    onChange={(event) => {
                      setInitState({
                        ...initState,
                        openCropper: true,
                        image: URL.createObjectURL(event.target.files[0]),
                      })
                    }}
                  />
                  {initState.croppedImages.length > 0 && (
                    <>
                      {initState.croppedImages?.map((element, iIndex) => (
                        <div className="ml-2 mr-4">
                          <img
                            alt="croped images result"
                            key={`image-key-${iIndex}`}
                            src={element}
                            width="220"
                            height="350"
                          />
                          <span
                            className="ml-1"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              setInitState({
                                ...initState,
                                croppedImages: initState.croppedImages.filter(
                                  (element, index) => index !== iIndex
                                ),
                              })
                            }}
                          >
                            X
                          </span>
                        </div>
                      ))}
                    </>
                  )}
                </div>
                {initState.imageError && (
                  <span className="text-danger">Image is required</span>
                )}
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="d-flex justify-content-center">
            <Button color="default" type="submit">
              Create
            </Button>
          </CardFooter>
        </Card>
      </Form>
      {initState.openCropper && (
        <CropperModal
          isShow={initState.openCropper}
          image={initState.image}
          close={() => setInitState({ ...initState, openCropper: false })}
          x={4}
          y={6}
          setCroppedImage={async (image) => {
            setInitState({
              ...initState,
              croppedImages: initState.croppedImages.push(image),
            })
            // setCropImage(image);
          }}
        />
      )}
    </Container>
  )
}
export default CreateProduct
