import Home from 'pages/Home'
import Login from 'pages/Login'
import User from 'pages/user'
import Category from 'pages/category'
import Notificaton from 'pages/notification'
import Product from 'pages/product'

// import EditSizes from ''
import CreateProduct from 'pages/product/create'
import EditProduct from 'pages/product/edit'
import CreateCategory from 'pages/category/create'
import EditCategory from 'pages/category/edit'
import CreateUserAccount from 'pages/user/create'
import EditUserAccount from 'pages/user/edit'
import CreateNotification from 'pages/notification/create'
import EditNotification from 'pages/notification/edit'
import ContactInfo from 'pages/contactInfo'
import Setting from 'pages/setting'
import PaymentMethod from 'pages/setting/paymentMethod'
import CreatePaymentMethod from 'pages/setting/paymentMethod/create'
import EditPaymentMethod from 'pages/setting/paymentMethod/edit'
import Orders from 'pages/orders'
import EditOrder from 'pages/orders/edit'
import Admin from 'pages/admin'
import CreateAdminAccount from 'pages/admin/create'
import EditAdminAccount from 'pages/admin/edit'
import RoyalCustomer from 'pages/user/royal'
import UserReport from 'pages/user/report'

import ItemArrival from 'pages/arrival'
import CreateItemArrival from 'pages/arrival/create'

import Stock from 'pages/stock'
import CreateSizes from 'pages/sizes/create'
import EditSizes from 'pages/sizes/edit'
import { fromJS } from 'immutable'
import { getCurrentUser } from 'lib/getCurrentUser'

const routes = [
  {
    path: '/login',
    name: 'login',
    icon: 'fas fa-home text-primary',
    component: Login,
    layout: '/auth',
    invisible: true,
  },
  {
    path: '',
    name: 'Home',
    icon: 'fas fa-home text-primary',
    component: Home,
    layout: '/',
    show: true,
  },
  {
    collapse: true,
    name: 'Users Account',
    icon: 'fas fa-user-friends text-primary',
    state: 'usersCollapse',
    invisible: getCurrentUser()?.role === 'staff',
    views: [
      {
        path: 'user',
        name: 'List',
        icon: 'fas fa-list text-primary',
        miniName: 'A',
        component: User,
        layout: '/',
      },
      {
        path: 'user/create',
        name: 'Create',
        icon: 'fas fa-user-plus text-primary',
        miniName: 'C',
        component: CreateUserAccount,
        layout: '/',
        invisible: getCurrentUser()?.role === 'editor',
      },
      {
        path: 'user/edit/:id',
        name: 'Edit User',
        miniName: 'C',
        component: EditUserAccount,
        layout: '/',
        invisible: true,
      },
      {
        path: 'user/royal',
        name: 'Royal Customer',
        miniName: 'C',
        component: RoyalCustomer,
        layout: '/',
        invisible: true,
      },
      {
        path: 'user/report',
        icon: 'fas fa-users text-primary',
        name: 'User Report',
        miniName: 'C',
        component: UserReport,
        layout: '/',
      },
    ],
  },
  {
    collapse: true,
    name: 'Admin Account',
    icon: 'fas fa-user-cog text-primary',
    state: 'adminCollapse',
    invisible:
      getCurrentUser()?.role === 'staff' || getCurrentUser()?.role === 'editor',
    views: [
      {
        path: 'admin',
        name: 'List',
        icon: 'fas fa-list text-primary',
        miniName: 'A',
        component: Admin,
        layout: '/',
      },
      {
        path: 'admin/store',
        name: 'Create',
        icon: 'fas fa-user-plus text-primary',
        miniName: 'C',
        component: CreateAdminAccount,
        layout: '/',
        invisible: getCurrentUser()?.role === 'editor',
      },
      {
        path: 'admin/update/:id',
        name: 'EditAdmin',
        miniName: 'C',
        component: EditAdminAccount,
        layout: '/',
        invisible: true,
      },
    ],
  },
  {
    collapse: true,
    name: 'Category',
    icon: 'fas fa-layer-group text-primary',
    state: 'usersCategory',
    invisible: getCurrentUser()?.role === 'staff',
    views: [
      {
        path: 'category',
        name: 'List',
        icon: 'fas fa-list text-primary',
        component: Category,
        layout: '/',
      },
      {
        path: 'category/create',
        name: 'New',
        icon: 'fas fa-plus-circle text-primary',
        component: CreateCategory,
        layout: '/',
      },
      {
        path: 'category/edit/:id',
        name: 'New',
        icon: 'fas fa-home text-primary',
        component: EditCategory,
        layout: '/',
        invisible: true,
      },
    ],
  },
  {
    collapse: true,
    name: 'Inventory',
    icon: 'fas fa-boxes text-primary',
    state: 'stocksCollapse',
    show: true,
    views: [
      {
        path: 'arrival',
        name: 'Item Arrival',
        icon: 'fas fa-list text-primary',
        component: ItemArrival,
        layout: '/',
        invisible: false,
      },
      {
        path: 'stocks',
        name: 'Stock',
        icon: 'fas fa-list text-primary',
        component: Stock,
        layout: '/',
        invisible: false,
      },
      {
        path: 'arrival/create',
        name: 'New',
        icon: 'fas fa-plus-circle text-primary',
        component: CreateItemArrival,
        layout: '/',
        invisible: true,
      },
    ],
  },
  {
    collapse: true,
    name: 'Product',
    icon: 'fas fa-shopping-bag text-primary',
    state: 'productsCollapse',
    invisible: getCurrentUser()?.role === 'staff',
    views: [
      {
        path: 'product',
        name: 'List',
        icon: 'fas fa-list text-primary',
        component: Product,
        layout: '/',
      },
      {
        path: 'product/create',
        name: 'New',
        icon: 'fas fa-plus-circle text-primary',
        component: CreateProduct,
        layout: '/',
      },
      {
        path: 'product/edit/:id',
        name: 'Edit',
        icon: 'fas fa-home text-primary',
        component: EditProduct,
        layout: '/',
        invisible: true,
      },
    ],
  },
  // {
  //   collapse: true,
  //   name: 'Sizes',
  //   icon: 'fas fa-tshirt text-primary',
  //   state: 'sizesCollapse',
  //   views: [
  //     {
  //       path: 'size',
  //       name: 'List',
  //       icon: 'fas fa-list text-primary',
  //       component: Sizes,
  //       layout: '/',
  //     },
  //     {
  //       path: 'sizes/create',
  //       name: 'New',
  //       icon: 'fas fa-plus-circle text-primary',
  //       component: CreateSizes,
  //       layout: '/',
  //     },
  //     {
  //       path: 'sizes/edit/:id',
  //       name: 'Edit',
  //       icon: 'fas fa-home text-primary',
  //       component: EditSizes,
  //       layout: '/',
  //       invisible: true,
  //     },
  //   ],
  // },
  {
    path: 'order',
    name: 'Orders',
    icon: 'fas fa-clipboard-check text-primary',
    component: Orders,
    layout: '/',
    show: true,
  },
  {
    path: 'order/edit/:id',
    name: 'Edit Order',
    icon: 'fas fa-home text-primary',
    component: EditOrder,
    layout: '/',
    invisible: true,
  },
  {
    collapse: true,
    name: 'Annoucement',
    icon: 'fas fa-scroll text-primary',
    state: 'nosCollapse',
    invisible: getCurrentUser()?.role === 'staff',
    views: [
      {
        path: 'notification',
        name: 'List',
        icon: 'fas fa-list text-primary',
        component: Notificaton,
        layout: '/',
      },
      {
        path: 'notification/create',
        name: 'New',
        icon: 'fas fa-plus-circle text-primary',
        component: CreateNotification,
        layout: '/',
        invisible:
          getCurrentUser()?.role !== 'admin' &&
          getCurrentUser()?.role !== 'super_admin',
      },
      {
        path: 'notification/edit/:id',
        name: 'Edit',
        icon: 'fas fa-home text-primary',
        component: EditNotification,
        layout: '/',
        invisible: true,
      },
    ],
  },
  {
    path: 'contactinfo',
    name: 'Contact Info',
    icon: 'far fa-address-book text-primary',
    component: ContactInfo,
    invisible: getCurrentUser()?.role === 'staff',
    layout: '/',
  },
  {
    collapse: true,
    path: 'setting',
    name: 'Setting',
    icon: 'fas fa-cogs text-primary',
    component: Setting,
    layout: '/',
    invisible: getCurrentUser()?.role === 'staff',
    views: [
      {
        path: 'paymentMethod',
        name: 'Payment Method',
        icon: 'fas fa-money-bill-wave text-primary',
        component: PaymentMethod,
        layout: '/',
      },
      {
        path: 'paymentMethod/create',
        name: 'Create Payment Method',
        icon: 'fas fa-home text-primary',
        component: CreatePaymentMethod,
        layout: '/',
        invisible: true,
      },
      {
        path: 'paymentMethod/edit/:id',
        name: 'Edit Method',
        icon: 'fas fa-home text-primary',
        component: EditPaymentMethod,
        layout: '/',
        invisible: true,
      },
    ],
  },
]

export default routes
