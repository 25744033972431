import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
/** @jsxImportSource @emotion/react */ //this need to be import for css prop
import { css } from '@emotion/react'
// nodejs library that concatenates classes
import classnames from 'classnames'
import { storeCache } from 'lib/cache'
import { sendData } from 'utli/apiService'
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap'

function Login() {
  const history = useHistory()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const [{ loading }, setLoginStatus] = useState({
    loading: false,
    error: null,
  })

  const submitData = async (values) => {
    const response = await sendData('admin/login', values)
    setLoginStatus({ loading: true, error: null })
    if (response) {
      storeCache('token', response.data.accessToken)
      storeCache('userinfo', JSON.stringify(response.data.adminInfo))
      window.location = '/'
    }
    setLoginStatus({ loading: false, error: null })
  }

  return (
    <>
      <Container className="mt-5 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardHeader
                css={styles.headerText}
                className="bg-default text-center mb-4"
              >
                NT Dashboard
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Form onSubmit={handleSubmit(submitData)}>
                  <FormGroup className={classnames('mb-3')}>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-single-02" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <input
                        className="form-control pl-2"
                        placeholder="UserName"
                        type="text"
                        {...register('username', {
                          required: 'Username is required',
                        })}
                      />
                    </InputGroup>
                    {errors.username && (
                      <span className="text-danger">
                        {errors?.username?.message}
                      </span>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <input
                        className="form-control pl-2"
                        placeholder="Password"
                        type="password"
                        {...register('password', {
                          required: 'Password is required',
                        })}
                      />
                    </InputGroup>
                    {errors.password && (
                      <span className="text-danger">
                        {errors?.password?.message}
                      </span>
                    )}
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      className="my-4"
                      color="default"
                      type="submit"
                      disabled={loading}
                    >
                      Sign in
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

const styles = {
  headerText: css`
    color: #fff;
  `,
}

export default Login
