import React, { useState } from 'react'
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Table,
  Label,
} from 'reactstrap'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import useQuery from 'hooks/useQuery'
import CustomDatePicker from 'components/DatePicker/customDatePicker'
const RoyalCustomer = () => {
  const router = useHistory()
  const headers = ['#', 'User Name', 'Phone', 'Orders']
  const query = queryString.parse(router.location.search)

  let url = 'users/royal?'
  if (query.search) url += `keyword=${query.search}`
  if (query.start_date && query.end_date)
    url += `&start_date=${query.start_date}&end_date=${query.end_date}`

  const {
    response: { userList },
    loading,
    error,
  } = useQuery(url)

  const [startDate, setStartDate] = React.useState('')
  const [endDate, setEndDate] = React.useState('')

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <>
      <Card>
        <CardBody>
          <CardTitle className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Royal Customers</h3>
              </Col>
              <Col className="text-right" xs="6">
                <Button
                  className="btn-round btn-icon"
                  color="success"
                  size="sm"
                  href={`${router.location.pathname}`}
                >
                  <span className="btn-inner--text">View All</span>
                </Button>
              </Col>
            </Row>
          </CardTitle>
          <CardTitle>
            <Row>
              <Col md={3}>
                <Label>Start Date</Label>
                <CustomDatePicker onChange={(day) => setStartDate(day)} />
              </Col>
              <Col md={3}>
                <Label>End Date</Label>
                <CustomDatePicker onChange={(day) => setEndDate(day)} />
              </Col>
              <Col md={3}>
                <br />
                <Button
                  color="primary"
                  onClick={() => {
                    if (startDate && endDate) {
                      router.push(
                        `/user/royal?start_date=${startDate}&end_date=${endDate}`
                      )
                    }
                  }}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </CardTitle>
          <div className="d-flex justify-content-end">
            <span>Total {userList.total} users</span>
          </div>
          <Table responsive className="items-center">
            <thead>
              <tr>
                {headers.map((element, index) => {
                  return (
                    <th
                      className=" sort"
                      data-sort={element}
                      scope="col"
                      key={`user-account-new-header-${index}`}
                    >
                      {element}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {userList.data.map((element, index) => {
                return (
                  <tr key={`user-account-list-${element.id}`}>
                    <td>{index + 1}</td>
                    <td>{element.name}</td>
                    <td>{element.phone}</td>
                    <td>{element.orders_count}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  )
}
export default RoyalCustomer
