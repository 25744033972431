import React from 'react'
import useQuery from 'hooks/useQuery'
import { Card, CardBody, Table, Button, Col, Row, CardTitle } from 'reactstrap'
import { confirmAlert } from 'react-confirm-alert'
import { useHistory } from 'react-router-dom'
import { sendData } from 'utli/apiService'
import { getCurrentUser } from 'lib/getCurrentUser'
function PaymentMethod() {
  const router = useHistory()
  const {
    response: { paymentInfoList },
    loading,
    error,
  } = useQuery('paymentinfos')

  const deleteData = (id) => {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Are you sure to delete? Data cannot be recovered.',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            const response = await sendData(`paymentinfos/delete/${id}`)
            if (response) {
              window.location.reload()
            }
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    })
  }

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <div>
      <Card>
        <CardBody>
          <CardTitle className="border-0">
            <Row>
              <Col xs="6">
                <h3 className="mb-0">Payment Infos</h3>
              </Col>
              <Col className="text-right" xs="6">
                {getCurrentUser().role === 'admin' ||
                  (getCurrentUser().role === 'super_admin' && (
                    <Button
                      className="btn-round btn-icon"
                      color="primary"
                      onClick={(e) => router.push('/paymentMethod/create')}
                      size="sm"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-plus" />
                      </span>
                      <span className="btn-inner--text">Add New</span>
                    </Button>
                  ))}
              </Col>
            </Row>
          </CardTitle>
          <Table className="mt-3">
            <thead>
              <th>No</th>
              <th>Image</th>
              <th>Name</th>
              <th>Number</th>
              <th>Actions</th>
            </thead>
            <tbody>
              {paymentInfoList.data.map((element, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>
                    <img src={element.image} width={60} height={60} />
                  </td>
                  <td>{element.name}</td>
                  <td>{element.number}</td>
                  <td>
                    {getCurrentUser().role === 'admin' ||
                      (getCurrentUser().role === 'super_admin' && (
                        <>
                          <Button
                            size="sm"
                            className="btn-icon-only"
                            color="success"
                            onClick={() => {
                              router.push(`/paymentMethod/edit/${element.id}`)
                            }}
                          >
                            <span className="btn-inner--icon">
                              <i className="fas fa-pen" />
                            </span>
                          </Button>
                          <Button
                            size="sm"
                            className="btn-icon-only"
                            color="danger"
                            onClick={() => deleteData(element.id)}
                            disabled={element.products_count > 0}
                          >
                            <span className="btn-inner--icon">
                              <i className="fas fa-trash" />
                            </span>
                          </Button>
                        </>
                      ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  )
}

export default PaymentMethod
