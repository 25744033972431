import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Col,
  Container,
  Row,
  Form,
} from 'reactstrap'
import Select from 'react-select'
import { useForm, Controller } from 'react-hook-form'
import useQuery from 'hooks/useQuery'
import CustomDatePicker from 'components/DatePicker/customDatePicker'
import { getTodayDate } from 'lib/dateFormat'
import { sendData } from 'utli/apiService'
import { NotificationManager } from 'react-notifications'
import './create.scss'
const CreateItemArrival = () => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm()

  const { response, loading, error } = useQuery('products')
  const products = response?.productlist?.data?.map((element) => ({
    value: element.id,
    label: `${element.name} (${element.colour}) (${element.size})`,
  }))

  const submitData = async (values) => {
    values.total = values.price * values.quantity
    const response = await sendData('purchases/store', values)
    if (response) {
      NotificationManager.success('Save')
      reset()
    }
  }

  if (loading) return <h3>Loading...</h3>
  if (error) return <h3>{error.response.message}</h3>
  return (
    <Container>
      <Form onSubmit={handleSubmit(submitData)}>
        <Card>
          <CardTitle className="pt-2 pl-4 bg-info">
            <h3 className="text-white">New Item Arrival</h3>
          </CardTitle>
          <CardBody>
            <Row>
              <Col md="6" sm="12" className="mb-3">
                <label>Product</label>

                <Controller
                  control={control}
                  name="product_id"
                  rules={{ required: true }}
                  defaultValue={products[0]?.value}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      inputRef={ref}
                      classNamePrefix="mySelect"
                      options={products}
                      defaultValue={products[0]?.value}
                      value={products.find((c) => c.value === value)}
                      onChange={(val) => onChange(val.value)}
                    />
                  )}
                />

                {errors.product_id && (
                  <span className="text-danger">
                    {errors?.product_id?.message}
                  </span>
                )}
              </Col>
              <Col md="6" sm="12" className="mb-3">
                <label>Price</label>
                <input
                  type={'number'}
                  className="form-control"
                  {...register('price', {
                    required: 'Price is required',
                  })}
                />
                {errors.price && (
                  <span className="text-danger">{errors?.price?.message}</span>
                )}
              </Col>
              <Col md="6" sm="12" className="mb-3">
                <label>Quantity</label>
                <input
                  type={'number'}
                  className="form-control"
                  {...register('quantity', {
                    required: 'Quantity is required',
                  })}
                />
                {errors.quantity && (
                  <span className="text-danger">
                    {errors?.quantity?.message}
                  </span>
                )}
              </Col>
              <Col md="6" sm="12" className="mb-3">
                <label>Date</label>
                <Controller
                  control={control}
                  name="date"
                  rules={{ required: true }}
                  defaultValue={getTodayDate()}
                  render={({ field: { onChange, value, ref } }) => (
                    <CustomDatePicker
                      inputRef={ref}
                      onChange={(day) => onChange(day)}
                      value={value}
                    />
                  )}
                />
                {errors.date && (
                  <span className="text-danger">{errors?.date?.message}</span>
                )}
              </Col>
            </Row>
          </CardBody>
          <CardFooter className="d-flex justify-content-center">
            <Button color="default" type="submit">
              Create
            </Button>
          </CardFooter>
        </Card>
      </Form>
    </Container>
  )
}
export default CreateItemArrival
